export const menuItems = [
    {
        id: 10,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 20,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 30,
        roles: ['ROLE_ADMIN'],
        label: 'menuitems.admin.text',
        icon: 'ri-admin-line',
        subItems: [
            {
                id: 31,
                roles: ['RETIRAR'],
                label: 'menuitems.admin.list.users',
                link: '/admin/user'
                
            },
            {
                id: 32,
                label: 'menuitems.admin.list.patients',
                link: '/admin/patient'
            },
            {
                id: 33,
                label: 'menuitems.admin.list.professionals',
                link: '/admin/professional'
            },
        
        ]
    },
    {
        id: 4,
        label: 'menuitems.procedure.text',
        icon: ' ri-calendar-2-line',
        subItems: [
            {
                id: 41,
                roles: ['ROLE_ADMIN'],
                label: 'menuitems.procedure.list.list',
                link: '/procedure/list'
            },
            {
                roles: ['RETIRAR'],
                id: 43,
                label: 'menuitems.procedure.list.calendar',
                link: '/procedure/calendar'
            },
            {
                roles: ['ROLE_PROFESSIONAL_'],
                id: 43,
                label: 'menuitems.procedure.list.professional',
                link: '/procedure/prof'
            },
            
            
        ]
    },
    {
        id: 4,
        label: 'menuitems.aba.text',
        icon: 'mdi mdi-human-male-child',
        subItems: [
            {
                id: 41,
                label: 'menuitems.aba.pei',
                link: '/aba/pei'
            },
            {
                roles: ['RETIRAR'],
                id: 43,
                label: 'menuitems.aba.pei',
                link: '/procedure/calendar'
            },
            
            
        ]
    },
    
    
]